import React, { useRef, useState } from 'react'
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { loader } from "../../loader";
import { postData } from "../../axios/apiHelper";
import { ENDPOINT } from "../../axios/apiConfig";
import { InformedSurveyValidations } from '../Validations/SurveyFormValidations/InformedSurveyValidations';

const InformedSurvey = () => {

  const [formInputs, setFormInputs] = useState({ career: '', work_path: '', biggest_challenge: [] ,second_biggest_challenge: []});
  const [error, setError] = useState("");
  const [questionOneAnswered, setQuestionOneAnswered] = useState(false);
  const formRef = useRef(null);
  const biggestChallengeOptions = ['Conference ROI', 'Long term engagement', 'Increasing ROI on content', 'AI Automate outreach'];
  const secondbiggestchallenge = ['Investigator relationship', 'Building long term engagement past the trial', 'Learn which materials are used by investigators'];

  // const handleChange = (e, field, value) => {
  //   if (field === 'career') {
  //     setFormInputs(prevFormInputs => ({
  //       ...prevFormInputs,
  //       [field]: value,
  //     }));
  //     setQuestionOneAnswered(true);
  //   } else if (field === 'biggest_challenge') {
  //     const { checked } = e.target;
  //     setFormInputs(prevFormInputs => ({
  //       ...prevFormInputs,
  //       biggest_challenge: checked
  //         ? [...prevFormInputs.biggest_challenge, value]
  //         : prevFormInputs.biggest_challenge.filter(item => item !== value),
  //     }));
  //   } else if (field === 'second_biggest_challenge') {
  //     const { checked } = e.target;
  //     setFormInputs(prevFormInputs => ({
  //       ...prevFormInputs,
  //       second_biggest_challenge: checked
  //         ? [...prevFormInputs.second_biggest_challenge, value]
  //         : prevFormInputs.second_biggest_challenge.filter(item => item !== value),
  //     }));
  //   } else {
  //     // Handle resetting second_biggest_challenge when work_path changes
  //     if (field === 'work_path' && formInputs.work_path !== value) {
  //       // Reset second_biggest_challenge to empty array
  //       setFormInputs(prevFormInputs => ({
  //         ...prevFormInputs,
  //         second_biggest_challenge: [],
  //       }));
  //     }
  
  //     setFormInputs(prevFormInputs => ({
  //       ...prevFormInputs,
  //       [field]: value,
  //     }));
  //   }
  // };

  //   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(formInputs);
//   };

  const handleChange = (e, field, value) => {
    if (field === 'career') {
      setFormInputs(prevFormInputs => ({
        ...prevFormInputs,
        [field]: value,
      }));
      setQuestionOneAnswered(true);
    } else if (field === 'biggest_challenge') {
      const { checked } = e.target;
      setFormInputs(prevFormInputs => ({
        ...prevFormInputs,
        biggest_challenge: checked
          ? [...(prevFormInputs.biggest_challenge || []), value]
          : (prevFormInputs.biggest_challenge || []).filter(item => item !== value),
      }));
    } else if (field === 'second_biggest_challenge') {
      const { checked } = e.target;
      setFormInputs(prevFormInputs => ({
        ...prevFormInputs,
        second_biggest_challenge: checked
          ? [...(prevFormInputs.second_biggest_challenge || []), value]
          : (prevFormInputs.second_biggest_challenge || []).filter(item => item !== value),
      }));
    } else {
      // Handle resetting second_biggest_challenge when work_path changes
      if (field === 'work_path' && formInputs.work_path !== value) {
        // Reset second_biggest_challenge to empty array
        setFormInputs(prevFormInputs => ({
          ...prevFormInputs,
          second_biggest_challenge: [],
        }));
      }
  
      setFormInputs(prevFormInputs => ({
        ...prevFormInputs,
        [field]: value,
      }));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
    //   console.log('Form Inputs:', formInputs);
    const error = InformedSurveyValidations(formInputs);
      if (Object.keys(error)?.length) {
        setError(error);
        return;
      } else{
      const data = {
        career: formInputs?.career ?? '',
        work_path: formInputs?.work_path ?? '', 
        biggest_challenge: formInputs?.biggest_challenge ?? [],
        second_biggest_challenge: formInputs?.second_biggest_challenge ?? []
      };
  
      const body = {
        surveyData: data,
        event_id:1,
        formType: 3
      };
      loader("show");
      console.log('POST Body:', body);
      const response = await postData(ENDPOINT.STORE_INFORMED_SURVEY_DATA, body);
      if (response?.status === 200) {
        setFormInputs({});
        setError();
        formRef.current.reset();
        formRef.current.scrollIntoView({ behavior: 'smooth' });
        toast.success(response?.data?.message);
       
      }
      loader("hide");
    }} catch (err) {
      window.location.reload();
        loader("hide");
      console.error('Error:', err);
    }
  };
  

  return (
    <>
    <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
  <div class="loader" id="custom_loader">
    <div class="loader_show">
      <span class="loader-view"> 
      </span>
    </div>
  </div>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    <div className="check-survey informed_survey">
              <Container>
                <Row>
                  <div className="survey-block">
                    <div className="survey-header d-flex justify-content-center">
                      <Col>
                        <h2>Informed Survey </h2>
                      </Col>
                    </div>
                    <div className="survey-question">
                      <Form
                       onSubmit={handleSubmit}
                     ref={formRef}
                       >

                        <Form.Group className="mb-3">
                          <Form.Label column sm={12} className="label-main">
                            1. Where are you in your career?
                          </Form.Label>
                          <Col sm={12} className="d-flex flex-wrap mb-3">
                            <Form.Check
                              type="radio"
                              name="career"
                              label="Student - looking forward to starting my career"
                              onChange={(e) =>
                                handleChange(e, "career", "Student - looking forward to starting my career")
                              }
                              checked={
                                formInputs?.career==
                                "Student - looking forward to starting my career"
                              }
                            />

                            <Form.Check
                              type="radio"
                              name="career"
                              label="Early stage - learning the ropes"
                              onChange={(e) =>
                                handleChange(e, "career", "Early stage - learning the ropes")
                              }
                              checked={
                                formInputs?.career ==
                                "Early stage - learning the ropes"
                              }
                            />

                            <Form.Check
                              type="radio"
                              name="career"
                              label="Moving through the ranks with management responsibility"
                              onChange={(e) =>
                                handleChange(e, "career", "Moving through the ranks with management responsibility")
                              }
                              checked={
                                formInputs?.career ==
                                "Moving through the ranks with management responsibility"
                              }
                            />

                            <Form.Check
                              type="radio"
                              name="career"
                              label="Mid / senior management"
                              onChange={(e) =>
                                handleChange(e, "career", "Mid / senior management")
                              }
                              checked={
                                formInputs?.career ==
                                "Mid / senior management"
                              }
                            />

                            <Form.Check
                              type="radio"
                              name="career"
                              label="Starting to think more about an active retirement!"
                              onChange={(e) =>
                                handleChange(e, "career", "Starting to think more about an active retirement!")
                              }
                              checked={
                                formInputs?.career ==
                                "Starting to think more about an active retirement!"
                              }
                            />
                            {error?.career ? (
                              <div className="login-validation">
                                {error?.career}
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Form.Group>

                       

                        {/* {questionOneAnswered && ( */}
                           {formInputs.career && (
                        <Form.Group className="mb-3">
                            <Form.Label column sm={12} className="label-main">
                            What is your biggest challenge this year?
                            </Form.Label>
                            <Col sm={12} className="d-flex flex-wrap mb-3">
                            {biggestChallengeOptions.map(option => (
                                <Form.Check
                                key={option}
                                type="checkbox"
                                label={option}
                                name="biggest_challenge"
                                onChange={(e) => handleChange(e, 'biggest_challenge', option)}
                                checked={Array.isArray(formInputs.biggest_challenge) && formInputs.biggest_challenge.includes(option)}
                                />
                            ))}
                            </Col>
                        </Form.Group>
                        )}

                        <Form.Group className="mb-3">
                          <Form.Label column sm={12} className="label-main">
                            2. What is your work path?
                          </Form.Label>
                          <Col sm={12} className="d-flex flex-wrap mb-3">
                            <Form.Check
                              type="radio"
                              name="work_path"
                              label="R&D Management"
                              onChange={(e) =>
                                handleChange(e, "work_path", "R&D Management")
                              }
                              checked={
                                formInputs?.work_path ==
                                "R&D Management"
                              }
                            />
 
                            <Form.Check
                              type="radio"
                              name="work_path"
                              label="Life science Marketing"
                              onChange={(e) =>
                                handleChange(e, "work_path", "Life science Marketing")
                              }
                              checked={
                                formInputs?.work_path ==
                                "Life science Marketing"
                              }
                            />
 
                            <Form.Check
                              type="radio"
                              name="work_path"
                              label="Researcher"
                              onChange={(e) =>
                                handleChange(e, "work_path", "Researcher")
                              }
                              checked={
                                formInputs?.work_path ==
                                "Researcher"
                              }
                            />
 
                            <Form.Check
                              type="radio"
                              name="work_path"
                              label="Student"
                              onChange={(e) =>
                                handleChange(e, "work_path", "Student")
                              }
                              checked={
                                formInputs?.work_path ==
                                "Student"
                              }
                            />
 
                            <Form.Check
                              type="radio"
                              name="work_path"
                              label="MedComs"
                              onChange={(e) =>
                                handleChange(e, "work_path", "MedComs")
                              }
                              checked={
                                formInputs?.work_path ==
                                "MedComs"
                              }
                            />
                            {error?.work_path ? (
                              <div className="login-validation">
                                {error?.work_path}
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Form.Group>

                        {formInputs?.work_path === 'R&D Management' || formInputs?.work_path === 'Life science Marketing' ? (
                        <Form.Group className="mb-3">
                            <Form.Label column sm={12} className="label-main">
                            What is your biggest challenge this year?
                            </Form.Label>
                            <Col sm={12} className="d-flex flex-wrap mb-3">
                            {secondbiggestchallenge.map(option => (
                                <Form.Check
                                key={option}
                                type="checkbox"
                                label={option}
                                name="second_biggest_challenge"
                                onChange={(e) => handleChange(e, 'second_biggest_challenge', option)}
                                checked={Array.isArray(formInputs.second_biggest_challenge) && formInputs.second_biggest_challenge.includes(option)}
                                />
                            ))}
                            </Col>
                        </Form.Group>
                        ) : null}

                        <Button type="submit">Submit</Button>
                      </Form>
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
            </>
  )
}

export default InformedSurvey


