import { useState, useRef, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { ENDPOINT } from "../../axios/apiConfig";
import { postData } from "../../axios/apiHelper";
import { SurveyQuestionFormValidations } from "../Validations/SurveyFormValidations/SurveyQuestionFormValidations";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useSearchParams } from 'react-router-dom';
import { loader } from "../../loader";
import { LEXSurveyQuestionFormValidations } from "../Validations/LEXSurveyQuestionFormValidation";
import { Modal } from "react-bootstrap";


const SurveyQuestionForm = () => {
  let questionCount=1

  const [eventId, setEvent] = useState({
    id: 0,
    companyId: 0
  })
  const [noEventFound, setNoEventFound] = useState(false);
  const [apiCallStatus, setApiCallStatus] = useState(false);
  const [isSurveySurveySubmitted, setIsSurveySurveySubmitted] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  var encrypted_us = queryParams.get("uhsjdksdk") ? queryParams.get("uhsjdksdk") : 0;

  const [searchParams] = useSearchParams();
  let parms = searchParams.get("event");
  const lexForm = parms && parms.includes("LEX-210")
  let atnd=searchParams.get("atnd");
  const remote_attendees = atnd && atnd.includes(1)
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const questions = [
    {
      name: 'investigator_meeting',
      label: 'How do you rate the Investigator Meeting overall?',
      options: [
        { value: 'excellent', label: 'Excellent' },
        { value: 'above average', label: 'Above average' },
        { value: 'acceptable (average)', label: 'Acceptable (average)' },
        { value: 'below average', label: 'Below average' },
        { value: 'poor', label: 'Poor' },
        { value: 'n/a (no answer)', label: 'N/A (no answer)' },
      ],
      showInput: false

    },
    {
      name: 'presentations',
      label: 'How do you rate the quality of the presentations and discussions?',
      options: [
        { value: 'excellent', label: 'Excellent' },
        { value: 'above average', label: 'Above average' },
        { value: 'acceptable (average)', label: 'Acceptable (average)' },
        { value: 'below average', label: 'Below average' },
        { value: 'poor', label: 'Poor' },
        { value: 'n/a (no answer)', label: 'N/A (no answer)' },
      ],
      showInput: false

    },
    {
      name: 'strategical_and_operational',
      label: 'The information provided and the topics covered were relevant to me and enhanced my understanding of the strategical and operational goals of the LEX-210 study.',
      options: [
        { value: 'strongly agree', label: 'Strongly agree' },
        { value: 'agree', label: 'Agree' },
        { value: 'neither agree nor disagree', label: 'Neither agree nor disagree' },
        { value: 'disagree', label: 'Disagree' },
        { value: 'strongly disagree', label: 'Strongly disagree' },
        { value: 'n/a (no answer)', label: 'N/A (no answer)' },
      ],
      showInput: false

    },
    {
      name: 'discussion_session',
      label: 'I consider specifically the discussion session (incl. polling/voting) as further helpful to improve study implementation to optimize potential patient identification and enrollment at my site.',
      options: [
        { value: 'strongly agree', label: 'Strongly agree' },
        { value: 'agree', label: 'Agree' },
        { value: 'neither agree nor disagree', label: 'Neither agree nor disagree' },
        { value: 'disagree', label: 'Disagree' },
        { value: 'strongly disagree', label: 'Strongly disagree' },
        { value: 'n/a (no answer)', label: 'N/A (no answer)' },
      ],
      showInput: false

    },
    {
      name: 'interesting_or_beneficial',
      label: 'Is there any topic or presentation of this meeting that you felt was particularly interesting or beneficial?',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      showInput: true
      ,
      ifYes: {
        name: 'interesting_or_beneficial_input',
        label: 'Please indicate'
      },
      ifNo: {
        name: 'interesting_or_beneficial_input',
        label: 'What have you missed?'
      }

    },
    {
      name: 'technical_aspects',
      label: 'How do you rate the technical aspects (light, sound, video quality) of the Investigator Meeting webcasting?',
      options: [
        { value: 'excellent', label: 'Excellent' },
        { value: 'above average', label: 'Above average' },
        { value: 'acceptable (average)', label: 'Acceptable (average)' },
        { value: 'below average', label: 'Below average' },
        { value: 'poor', label: 'Poor' },
        { value: 'n/a (no answer)', label: 'N/A (no answer)' },
      ],
      showInput: false

    },
    {
      name: 'feedback',
      label: 'Do you have any further feedback or questions regarding the Investigator Meeting held, or about any other aspects related to the LEX-210 study?',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      showInput: true,
      ifYes: {
        name: 'feedback_input',
        label: ''
      },

    },
  ];

  const EventDataFun = async () => {
    try {
      // loader("show")
      const result = await postData(ENDPOINT.EVENT_ID, {
        userId:encrypted_us,
        eventCode: queryParams.get("event")
      })
      if (result?.data?.data == 0) {
        setNoEventFound(true);
      } else {
        setIsSurveySurveySubmitted(result.data.data?.isSurveySurveySubmitted)
        setModalIsOpen(result.data.data?.isSurveySurveySubmitted);

        setEvent(result.data.data)
      }
      setApiCallStatus(true);
      // loader("hide")
    } catch (err) {
      // loader("hide")
      console.log("-err", err)
    }
  }
  useEffect(() => {
    EventDataFun()
  }, [])
  const formRef = useRef(null);
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED_DESIGN;
  const [formInputs, setFormInputs] = useState({});
  const [error, setError] = useState("");

  const handleChange = (e, isSelectedName, rating) => {
    if (
      isSelectedName === "patient_case" ||
      isSelectedName === "clinical_practice" ||
      isSelectedName === "recommend"
    ) {
      const updateForm = {
        [isSelectedName]: {
          ...formInputs[isSelectedName],
          [e.target.name]:
            e?.target?.name == "others" ? e?.target?.value : rating,
        },
      };

      setFormInputs({ ...formInputs, ...updateForm });
    }

    else {
      const isOther = isSelectedName === "other";
      const checkboxValue = isOther ? true : e.target.checked;
      const updateForm = isSelectedName
        ? {
          [e.target.name]: {
            [isSelectedName]: isOther ? e?.target?.value : checkboxValue,
          },
        }
        : { [e.target.name]: e?.target?.value };
      setFormInputs({ ...formInputs, ...updateForm });
    }
  };
  const handleChangeRd = (e, isSelectedName, rating) => {
   if(!isSurveySurveySubmitted){ const updatedFormInputs = { ...formInputs };

      updatedFormInputs[isSelectedName] = rating;
      if(isSelectedName=="interesting_or_beneficial") 
        updatedFormInputs["interesting_or_beneficial_input"] = "";
      if(isSelectedName=="feedback" && rating=="yes") 
        updatedFormInputs["feedback_input"] = "";

    setFormInputs(updatedFormInputs);}
  };



  const handleSubmit = async (e) => {
   
    e.preventDefault();
    try {
      const error = SurveyQuestionFormValidations(formInputs);
      if (Object.keys(error)?.length) {
        setError(error);
        return;
      } else {
        let data = {

          suggestion: formInputs?.suggestion ? formInputs?.suggestion : "  ",

          patient_case: {
            patient_case_rating: formInputs?.patient_case?.patient_case_rating
              ? formInputs?.patient_case?.patient_case_rating
              : "",
          },

          clinical_practice: {
            future_clinical: formInputs?.clinical_practice?.future_clinical
              ? formInputs?.clinical_practice?.future_clinical
              : "",
          },

          recommend: {
            recommend_clinical: formInputs?.recommend?.recommend_clinical
              ? formInputs?.recommend?.recommend_clinical
              : "",
          },
        };

        let body = {
          surveyData: data,
          event_id: eventId?.id ? eventId?.id : 0,
          userId: encrypted_us,
          formType: 2
        }
        loader("show");
        const response = await postData(ENDPOINT.STORE_SURVEY_DATA, body);
        if (response?.status == 200) {
          setFormInputs({});
          setError()
          formRef.current.reset();
          formRef.current.scrollIntoView({ behavior: 'smooth' });
          toast.success(response?.data?.message)
        }
        loader("hide");
      }
    } catch (err) {
      loader("hide");
      console.log("--err", err);
    }
  };


  const handleLEXSubmit = async (e) => {
   
    e.preventDefault();
    try {
      const error = LEXSurveyQuestionFormValidations(formInputs,remote_attendees);
  
      if (Object.keys(error).length) {
        setError(error);
        return;
      } else {
        // Dynamic data mapping based on formInputs
        let data = {};
        const fields = [
          "investigator_meeting",
          "presentations",
          "strategical_and_operational",
          "discussion_session",
          "interesting_or_beneficial",
          "technical_aspects",
          "feedback",
        ];
  
        fields.forEach((field) => {
          data[field] = formInputs[field] || " ";
        });
  
        // Additional dynamic inputs handling
        if (formInputs.interesting_or_beneficial_input) {
          data.interesting_or_beneficial_input = formInputs.interesting_or_beneficial_input;
        }
        if (formInputs.feedback_input) {
          data.feedback_input = formInputs.feedback_input;
        }
  
        let body = {
          surveyData: data,
          event_id: eventId?.id || 0,
          userId: encrypted_us,
          formType: 2
        };
  
        loader("show");
        const response = await postData(ENDPOINT.STORE_SURVEY_DATA, body);
  
        if (response?.status === 200) {
          setFormInputs({});
          setError({});
          formRef.current.reset();
          formRef.current.scrollIntoView({ behavior: 'smooth' });
          toast.success(response?.data?.message);
        }
  
        loader("hide");
        setModalIsOpen(true);
      }
    } catch (err) {
      loader("hide");
      console.log("--err", err);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="loader" id="custom_loader"><div className="loader_show"><span className="loader-view"> </span></div></div>
      {
        apiCallStatus ?
          noEventFound ?
            <>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <div className="page_not_found">
                <div className="page_not_found-inset">
                  <div className="not-found">
                    <h1>404</h1>
                  </div>
                  <h4>Page not found</h4>
                  <p>The link you clicked may be broken or the page may have been removed or renamed.</p>
                </div>
              </div>
            </>
            :
            <div className="col right-sidebar full-width-survey">
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <div 
              // className="check-survey lex-210"
              
              className={lexForm ? 'check-survey lex-210' : 'check-survey' }
              >
                <Container>
                  <Row>
                    <div className="survey-block">
                      <div className="survey-header d-flex justify-content-center">
                        <Col>
                          {lexForm ?
                            <h1> LEX-210 Post Event Survey </h1> :
                            <h1> Clinical Practice Post Event Survey </h1>}
                        </Col>
                      </div>
                      {lexForm ?
                        <div className="survey-question">
                          <Form onSubmit={handleLEXSubmit} ref={formRef}>
                            {/* {questions.map((question) => (
                              <RadioGroup
                                key={question.name}
                                name={question.name}
                                label={question.label}
                                options={question.options}
                                formInputs={formInputs}
                                handleChangeRd={handleChangeRd}
                                error={error}
                                showInput={question.showInput}
                                ifYes={question.ifYes}
                                ifNo={question.ifNo}
                              />
                            ))} */}

                            {/* {questions.map((question) => (
                              (remote_attendees && question.name === 'technical_aspects') && (
                                <RadioGroup
                                  key={question.name}
                                  name={question.name}
                                  label={question.label}
                                  options={question.options}
                                  formInputs={formInputs}
                                  handleChangeRd={handleChangeRd}
                                  error={error}
                                  showInput={question.showInput}
                                  ifYes={question.ifYes}
                                  ifNo={question.ifNo}
                                />
                              )
                            ))} */}

                            {
                            
                            questions.map((question,index) => 
                          {   
                            
                            
                            return (remote_attendees || question.name !== 'technical_aspects') && (
                                <RadioGroup
                                  key={question.name}
                                  name={question.name}
                                  index={questionCount++}
                                  label={question.label}
                                  options={question.options}
                                  formInputs={formInputs}
                                  handleChangeRd={handleChangeRd}
                                  error={error}
                                  showInput={question.showInput}
                                  ifYes={question.ifYes}
                                  ifNo={question.ifNo}
                                />
                              )}
                            
                            )}



                            <Button type="submit">Submit</Button>
                          </Form>
                        </div>
                        :

                        <div className="survey-question">
                          <Form onSubmit={handleSubmit} ref={formRef}>
                            <Form.Group className="mb-3">
                              <Form.Label column sm={12} className="label-main">
                                1. How relevant was this patient case to your clinical
                                practice?
                                <br />
                              </Form.Label>
                              <Col sm={12} className="d-flex flex-wrap">
                                <fieldset>
                                  <div className="rating-wrapper">
                                    <input
                                      type="radio"
                                      id="5-patient_case_rating"
                                      name="patient_case_rating"
                                      onChange={(e) =>
                                        handleChange(e, "patient_case", "5")
                                      }
                                      checked={
                                        formInputs?.patient_case?.patient_case_rating ==
                                        "5"
                                      }
                                    />
                                    <label
                                      htmlFor="5-patient_case_rating"
                                      className="star-rating"
                                    >
                                      <svg
                                        stroke="#019acc"
                                        strokeWidth="1px"
                                        fill="#fff"
                                        width="800px"
                                        height="800px"
                                        viewBox="0 0 32 32"
                                      >
                                        <path d="M16 4.588l2.833 8.719H28l-7.416 5.387 2.832 8.719L16 22.023l-7.417 5.389 2.833-8.719L4 13.307h9.167L16 4.588z" />
                                      </svg>
                                    </label>

                                    <input
                                      type="radio"
                                      id="4-patient_case_rating"
                                      name="patient_case_rating"
                                      onChange={(e) =>
                                        handleChange(e, "patient_case", "4")
                                      }
                                      checked={
                                        formInputs?.patient_case?.patient_case_rating ==
                                        "4"
                                      }
                                    />
                                    <label
                                      htmlFor="4-patient_case_rating"
                                      className="star-rating star"
                                    >
                                      <svg
                                        stroke="#019acc"
                                        strokeWidth="1px"
                                        fill="#fff"
                                        width="800px"
                                        height="800px"
                                        viewBox="0 0 32 32"
                                      >
                                        <path d="M16 4.588l2.833 8.719H28l-7.416 5.387 2.832 8.719L16 22.023l-7.417 5.389 2.833-8.719L4 13.307h9.167L16 4.588z" />
                                      </svg>
                                    </label>

                                    <input
                                      type="radio"
                                      id="3-patient_case_rating"
                                      name="patient_case_rating"
                                      onChange={(e) =>
                                        handleChange(e, "patient_case", "3")
                                      }
                                      checked={
                                        formInputs?.patient_case?.patient_case_rating ==
                                        "3"
                                      }
                                    />
                                    <label
                                      htmlFor="3-patient_case_rating"
                                      className="star-rating star"
                                    >
                                      <svg
                                        stroke="#019acc"
                                        strokeWidth="1px"
                                        fill="#fff"
                                        width="800px"
                                        height="800px"
                                        viewBox="0 0 32 32"
                                      >
                                        <path d="M16 4.588l2.833 8.719H28l-7.416 5.387 2.832 8.719L16 22.023l-7.417 5.389 2.833-8.719L4 13.307h9.167L16 4.588z" />
                                      </svg>
                                    </label>

                                    <input
                                      type="radio"
                                      id="2-patient_case_rating"
                                      name="patient_case_rating"
                                      onChange={(e) =>
                                        handleChange(e, "patient_case", "2")
                                      }
                                      checked={
                                        formInputs?.patient_case?.patient_case_rating ==
                                        "2"
                                      }
                                    />
                                    <label
                                      htmlFor="2-patient_case_rating"
                                      className="star-rating star"
                                    >
                                      <svg
                                        stroke="#019acc"
                                        strokeWidth="1px"
                                        fill="#fff"
                                        width="800px"
                                        height="800px"
                                        viewBox="0 0 32 32"
                                      >
                                        <path d="M16 4.588l2.833 8.719H28l-7.416 5.387 2.832 8.719L16 22.023l-7.417 5.389 2.833-8.719L4 13.307h9.167L16 4.588z" />
                                      </svg>
                                    </label>

                                    <input
                                      type="radio"
                                      id="1-patient_case_rating"
                                      name="patient_case_rating"
                                      onChange={(e) =>
                                        handleChange(e, "patient_case", "1")
                                      }
                                      checked={
                                        formInputs?.patient_case?.patient_case_rating ==
                                        "1"
                                      }
                                    />
                                    <label
                                      htmlFor="1-patient_case_rating"
                                      className="star-rating star"
                                    >
                                      <svg
                                        stroke="#019acc"
                                        strokeWidth="1px"
                                        fill="#fff"
                                        width="800px"
                                        height="800px"
                                        viewBox="0 0 32 32"
                                      >
                                        <path d="M16 4.588l2.833 8.719H28l-7.416 5.387 2.832 8.719L16 22.023l-7.417 5.389 2.833-8.719L4 13.307h9.167L16 4.588z" />
                                      </svg>
                                    </label>
                                  </div>
                                </fieldset>
                                {error?.patient_case ? (
                                  <div className="login-validation">
                                    {error?.patient_case}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label column sm={12} className="label-main">
                                2. I plan to attend future Clinical Practice patient
                                cases:
                              </Form.Label>
                              <Col sm={12} className="d-flex flex-wrap mb-3">
                                <Form.Check
                                  type="radio"
                                  name="future_clinical"
                                  label="Yes"
                                  onChange={(e) =>
                                    handleChange(e, "clinical_practice", "yes")
                                  }
                                  checked={
                                    formInputs?.clinical_practice?.future_clinical ==
                                    "yes"
                                  }
                                />

                                <Form.Check
                                  type="radio"
                                  name="future_clinical"
                                  label="No"
                                  onChange={(e) =>
                                    handleChange(e, "clinical_practice", "no")
                                  }
                                  checked={
                                    formInputs?.clinical_practice?.future_clinical ==
                                    "no"
                                  }
                                />
                                {error?.future_clinical ? (
                                  <div className="login-validation">
                                    {error?.future_clinical}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label column sm={12} className="label-main">
                                3. Would you recommend Clinical Practice to a colleague?
                              </Form.Label>
                              <Col sm={12} className="d-flex flex-wrap mb-3">
                                <Form.Check
                                  type="radio"
                                  name="recommend_clinical"
                                  label="Yes"
                                  onChange={(e) => handleChange(e, "recommend", "yes")}
                                  checked={
                                    formInputs?.recommend?.recommend_clinical == "yes"
                                  }
                                />

                                <Form.Check
                                  type="radio"
                                  name="recommend_clinical"
                                  label="No"
                                  onChange={(e) => handleChange(e, "recommend", "no")}
                                  checked={
                                    formInputs?.recommend?.recommend_clinical == "no"
                                  }
                                />
                                {error?.recommend_clinical ? (
                                  <div className="login-validation">
                                    {error?.recommend_clinical}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label column sm={12} className="label-main">
                                4. Please suggest a topic for a future Clinical Practice
                                patient case:
                              </Form.Label>
                              <Col sm={12} className="d-flex flex-wrap">
                                <Form.Control
                                  as="textarea"
                                  aria-label="With textarea"
                                  name="suggestion"
                                  value={
                                    formInputs?.suggestion ? formInputs?.suggestion : ""
                                  }
                                  onChange={(e) => handleChange(e)}
                                />
                                {error?.suggestion ? (
                                  <div className="login-validation">
                                    {error?.suggestion}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Form.Group>

                            <Button type="submit">Submit</Button>
                          </Form>
                        </div>}
                    </div>
                    <Modal
        className="modal send-confirm registration-popup"
        show={modalIsOpen}
        centered
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          {/* <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => {window.location.reload();setModalIsOpen(false)}}
          ></button> */}
        </Modal.Header>
        <Modal.Body>
          <>

            <h4>
              {isSurveySurveySubmitted?"You have already submitted the survey.":"We appreciate your time and effort in completing this survey. Your insights are invaluable to us. Thank you."}</h4>


            <div className="modal-buttons">
              {/* <button
                type="button"
                className="btn btn-primary btn-bordered"
                className={`btn btn-primary btn-bordered ${urlContainsAland ? 'island' : ''}`}
                onClick={() => {
                  setModalIsOpen(false);
                  loader("show");
                  if(redirectUrl!=""){
                    window.location.href = redirectUrl;
                  }else{
                    window.location.reload();
                  }
                 
                }}
              >
                Okay
              </button> */}
            </div>
          </>
        </Modal.Body>
      </Modal>
                  </Row>
                </Container>
              </div>
            </div>
          : null
      }
    </>
  );
};

const RadioGroup = ({ name, label, options, formInputs, handleChangeRd, error, showInput, ifYes, ifNo,index }) => {


  return (
    <Form.Group className="mb-3">
      <Form.Label column sm={12} className="label-main">
      {`${index}. `+label}
      </Form.Label>
      <Col sm={12} className="d-flex flex-wrap mb-3">
        {options.map((option) => (
          <Form.Check
            key={option.value}
            type="radio"
            name={name}
            label={option.label}
            onChange={(e) => handleChangeRd(e, name, option.value)}
            checked={formInputs?.[name] === option.value}
          />
        ))}
        {error?.[name] && (
          <div className="login-validation">{error?.[name]}</div>
        )}
        {showInput && formInputs[name] === 'yes' && ifYes?.name && (
          <><Form.Control
            as="textarea"
            placeholder={ifYes?.label}
            name={ifYes?.name}
            value={formInputs[ifYes?.name] || ''}
            onChange={(e) => handleChangeRd(e, ifYes?.name, e.target.value)}
            className="mt-2"
          />
        {error?.[ifYes?.name] && (
            <div className="login-validation">{error?.[ifYes?.name]}</div>
          )}
          </>
        )}
        {showInput && formInputs[name] === 'no' && ifNo?.name && (
         <> <Form.Control
            as="textarea"
            placeholder={ifNo?.label}
            name={ifNo?.name}
            value={formInputs[ifNo?.name] || ''}
            onChange={(e) => handleChangeRd(e, ifNo?.name, e.target.value)}
            className="mt-2"
          />
          {error?.[ifNo?.name] && (
            <div className="login-validation">{error?.[ifNo?.name]}</div>
          )}
          </>
        )}

      </Col>
    </Form.Group>
  );
};
export default SurveyQuestionForm;
